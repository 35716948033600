<template>
  <div class="home">
      <pmt-loading v-if="!$store.getters.isAdminAcces"></pmt-loading>
      <pmt-localadmin-home v-if="$store.getters.isAdminAcces"></pmt-localadmin-home>
  </div>
</template>

<script>
export default {
  name: 'AppHome',
  data () {
    return {}
  },
  created () {
    this.$store.getters.EventBus.$on('goTo', (to) => {
      this.$router.push(to)
    })
    this.$store.getters.EventBus.$on('switchTo', (to) => {
      this.$router.replace(to)
    })
  },
  computed: {
    loadComplete () {
      return this.$store.state.officeRulesModuleLoadComplete
    }
  },
  watch: {
    loadComplete (newValue) {
      if (!this.$store.getters.isAdminAcces && newValue) {
        this.$router.replace('/app')
      }
    }
  }
}
</script>
